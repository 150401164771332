.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
}
